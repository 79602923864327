import React, { Component } from "react";
import utilityFunctions from "../../store/utilityFunctions";
export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // Only allow button click when something has been edited
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  render() {
    return (
      <div className={"page"}>
        <h3>Help and Support Guide</h3>
      </div>
    );
  }
}
