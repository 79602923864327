import React, { Component } from "react";
import utilityFunctions from "../../store/utilityFunctions";
import errorFunctions from "../../store/errorFunctions";
import config from "../../config";
// import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      manuals: [],
      activeManualId: -1,
      // Only allow button click when something has been edited
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  retrieveManual = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "support/manual/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          manuals: res.sort((a, b) => a.id - b.id),
        });
      })
      .catch(() => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  componentDidMount() {
    this.retrieveManual();
  }
  render() {
    return (
      <div className={"page "}>
        <h3>User Manual</h3>
        <div className="pb-8 h-75 overflow-auto">
          {this.state.manuals.map((manual, index) => {
            return (
              <div className="m-3 ">
                <div className="fw-bold ">
                  <span className="m-2">{manual.title}</span>
                </div>
                <div className="m-3 border-b-2" dangerouslySetInnerHTML={{ __html: manual.description }} />
                <hr className="hr" />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
