import React, { Component } from "react";
import moment from "moment";
import Modal from "react-modal";
import ShippingLabel from "../ShippingLabel/ShippingLabel";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import config from "../../../config";
import errorFunctions from "../../../store/errorFunctions";
import "../../common.css";
import "./Table.css";

export default class TicketTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: null,
      loading: false,
      isTnCConfirm: false,
      ticket: [],
    };
  }

  handleChangeStatus = (ticketId) => {
    this.setState({ isLoading: false });
    let payload = {
      status: "Pending Receipt of Goods",
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${ticketId}/status/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ loading: false });
        this.props.retrieveDeviceDetail(); //mini reload
        if (this.state.ticket.return_label_url) window.open(this.state.ticket.return_label_url, "_blank");
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  confirmReturnLabel = (ticketId) => {
    this.setState({ isLoading: false });
    let payload = {
      is_generate_return_label: true,
    };
    this.props
      .protectedFetch(config.backendServer + `ticket/${ticketId}/return-label-confirm/`, "PATCH", payload, errorFunctions.checkResponseThenJson)
      .then(() => {
        this.setState({ loading: false, showShipping: false, isTnCConfirm: true });
        this.props.retrieveDeviceDetail(); //mini reload
        window.open(this.state.ticket.return_label_url, "_blank");
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  saveTrackingNumberService = () => {
    this.setState({ isLoading: false });
    let payload = {
      tracking_number_service: this.state.trackingNumberService,
    };
    this.props
      .protectedFetch(
        config.backendServer + `ticket/${this.state.ticket.id}/tracking-number/`,
        "PATCH",
        payload,
        errorFunctions.checkResponseThenJson
      )
      .then(() => {
        this.setState({ loading: false, showTrackingSvc: false, isTnCConfirm: true });
        this.props.retrieveDeviceDetail(); //mini reload
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  handleReturnLabelClick = () => {
    if (!this.state.ticket.is_generate_return_label) {
      this.confirmReturnLabel(this.state.ticket.id);
    } else if (this.state.ticket.return_label_url) {
      this.setState({ showShipping: false });
      window.open(this.state.ticket.return_label_url, "_blank");
    } else {
      console.error("Return label URL is not available.");
      alert("Return label URL is not available.");
      // Optionally, show a user-friendly message
    }
  };
  handleReturnLabelPdfClick = (ticket) => {
    if (ticket.status === "Pending Customer to Download Return Label") this.handleChangeStatus(ticket.id);
    this.setState({ showShippingPdf: true, ticket: ticket });
  };

  render() {
    return (
      <div>
        {this.props.tickets.length > 0 && (
          <TableContainer>
            <TableHead>
              <TableRow>
                <TableCell>Ticket Number</TableCell>
                <TableCell>Warranty Status</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Last Update Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tracking # to Customer</TableCell>
                <TableCell>Tracking # to Service</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tickets
                .sort((a, b) => (b.id - a.id ? -1 : 1))
                .map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.ticket_number}</TableCell>
                    <TableCell> {ticket.ticket_type.toUpperCase() === "OUT_OF_WARRANTY" ? " Out of Warranty" : " In Warranty"}</TableCell>
                    <TableCell>{moment(ticket.created).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{moment(ticket.last_activity_date).format("DD-MM-YYYY")}</TableCell>
                    <TableCell>{ticket.status}</TableCell>

                    <TableCell>
                      {ticket.tracking_number && ticket.tracking_number.includes("http") ? (
                        <a href={ticket.tracking_number} target={"blank"}>
                          Open
                        </a>
                      ) : (
                        ticket.tracking_number || ""
                      )}
                    </TableCell>
                    <TableCell>
                      {ticket?.is_generate_return_label && (
                        <div className="text-center d-flex gap-2">
                          {ticket.tracking_number_service && ticket.tracking_number_service.includes("http") ? (
                            <a href={ticket.tracking_number_service} target={"blank"} className=" h-auto m-auto form-control">
                              Open
                            </a>
                          ) : (
                            <span className="form-control">{ticket.tracking_number_service}</span>
                          )}
                          <button
                            className="k-btn k-btn-outline-primary  m-auto h-auto "
                            onClick={() => this.setState({ showTrackingSvc: true, ticket: ticket })}
                          >
                            Input
                          </button>
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {ticket?.return_label_url && (
                        <button
                          className="k-btn k-btn-primary h-auto"
                          onClick={() => {
                            this.setState({ showShipping: true, ticket: ticket });
                          }}
                        >
                          {ticket?.is_generate_return_label ? "View Accepted Terms" : "Generate Your Return Label"}
                        </button>
                      )}
                      {ticket.return_label && (
                        <button
                          className="k-btn k-btn-primary h-auto"
                          onClick={() => {
                            this.handleReturnLabelPdfClick(ticket);
                          }}
                        >
                          Return Label
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </TableContainer>
        )}
        <Modal isOpen={this.state.showShipping_} contentLabel={"Export Report"} className={"fullpage-modal"}>
          <ShippingLabel
            protectedFetch={this.props.protectedFetch}
            userDevice={this.state.ticket?.user_device}
            // disableInput={!!this.props.details.transdirect_status}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.state.ticket?.id}
            retrieveWarranty={() => this.props.retrieveWarranty(this.state.ticket?.id)}
          />
        </Modal>
        <Modal isOpen={this.state.showShipping} contentLabel={"Export Report"} className={"input-modal "}>
          <div className="card">
            <div className="card-header">
              <h3>Term and Conditions</h3>
            </div>
            <div className="card-body" style={{ width: "600px", height: "auto" }}>
              <p>Before proceeding, please carefully review and acknowledge the following:</p>{" "}
              <ol>
                <li>
                  By generating your return label, you confirm that you accept and are bound by the terms and conditions outlined in our{" "}
                  <a href="https://dreame.com.au/pages/warranty-policy" target="_blank" rel="noopener noreferrer">
                    Warranty Policy.
                  </a>
                </li>
                <li>
                  If, upon assessment of your device, it is determined that there is no fault or issue with the machine, you agree to pay an
                  assessment fee. The amount will be communicated to you in advance if necessary.
                </li>
              </ol>
              {!this.state.ticket?.is_generate_return_label && (
                <div>
                  <p>Please tick the box below to confirm your acceptance of these terms and conditions:</p>{" "}
                  <input
                    disabled={this.state.ticket?.is_generate_return_label}
                    type="checkbox"
                    id="acceptTerms"
                    name="acceptTerms"
                    onChange={() => this.setState({ isTnCConfirm: !this.state.isTnCConfirm })}
                  />{" "}
                  <label for="acceptTerms">I accept the terms and conditions</label>
                </div>
              )}
            </div>

            <div className="card-footer p-2">
              {this.state.ticket?.is_generate_return_label ? (
                <button
                  className={`k-btn h-auto mx-2 ${!this.state.isTnCConfirm ? "k-btn-secondary" : "k-btn-primary"}`}
                  onClick={() => {
                    this.setState({ showShipping: false });
                    window.open(this.state.ticket.return_label_url, "_blank");
                  }}
                  // disabled={!this.state.isTnCConfirm}
                >
                  Open
                </button>
              ) : (
                <button
                  className={`k-btn h-auto mx-2 ${!this.state.isTnCConfirm ? "k-btn-secondary" : "k-btn-primary"}`}
                  onClick={() => {
                    this.confirmReturnLabel(this.state.ticket.id);
                  }}
                  disabled={!this.state.isTnCConfirm}
                >
                  Confirm
                </button>
              )}

              <button
                className="k-btn k-btn-secondary h-auto mx-2"
                onClick={() => {
                  this.setState({ showShipping: false, isTnCConfirm: false });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showShippingPdf} contentLabel={"Export Report"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">View Return Label</div>
            <div className="card-body">
              {this.state.showShippingPdf && <embed type="application/pdf" src={this.state.ticket.return_label} width="800" height="600"></embed>}
            </div>
            <div className="card-footer">
              <button
                className="k-btn k-btn-secondary"
                onClick={() => {
                  console.log(this.state.ticket);
                  this.setState({ showShippingPdf: false });
                }}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showTrackingSvc} contentLabel={"Export Report"} className={"input-modal"}>
          <div className="card">
            <div className="card-header">Input tracking number</div>
            <div className="card-body ">
              <div>
                <label>Tracking Number</label>
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Tracking number"
                  className="form-control"
                  defaultValue={this.state.ticket.tracking_number_service}
                  onChange={(e) => this.setState({ trackingNumberService: e.target.value })}
                />
              </div>
            </div>
            <div className="card-footer d-flex gap-2">
              <button className="k-btn k-btn-primary h-auto mx-2" onClick={() => this.saveTrackingNumberService()}>
                Save
              </button>
              <button className="k-btn k-btn-secondary h-auto mx-2" onClick={() => this.setState({ showTrackingSvc: false })}>
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
