import React, { Component } from "react";
import "./UserProfile.css";
import "../common.css";
// import "../simple-grid.css";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import utilityFunctions from "../../store/utilityFunctions";
import LoadingPage from "../../components/Loading/Loading";
import { MdClose } from "react-icons/md";
import Select from "react-select";
// import { Redirect } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("body");

const countryOptions = [
  { value: "AU", label: "Australia" },
  { value: "NZ", label: "New Zeland" },
];
export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoading: false,
      showSuccess: false, // On update, show modal to show 'Data Saved'
      showFailure: false,
      fieldsEdited: false,
      first_name: "",
      last_name: "",
      company: "",
      address_line_one: "",
      address_line_two: "",
      stateVal: "",
      stateName: "",
      suburb: "",
      postcode: "",
      mobile: "",
      country: config.country,
      suburbOptions: [],
      postcodeOptions: [],
      stateOptions: [],
      // Only allow button click when something has been edited
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  componentDidMount() {
    this.retrieveProfile();
    this.searchLocationState(config.country);
  }
  searchLocationState = (country) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "location/state/?country=" + country, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        const options = res.map((x) => {
          return { value: x.code, label: x.name };
        });
        this.setState({
          stateOptions: options,
        });
      })
      .catch(() => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  searchLocationSuburb = (qState, qSuburb) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(
        config.backendServer + `location/suburb/?page_size=100&state=${qState}${qSuburb ? "&q=" + qSuburb : ""}`,
        "GET",
        null,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        const options = res.results.map((x) => {
          return { value: x.name, label: x.name };
        });
        console.log(options);
        this.setState({
          suburbOptions: options,
          // suburb: options[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  searchLocationPostcode = (qSuburb, qState) => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(
        config.backendServer + "location/postcode/?suburb=" + qSuburb + "&state=" + qState,
        "GET",
        null,
        errorFunctions.checkResponseThenJson
      )
      .then((res) => {
        const options = res.results.map((x) => {
          return { value: x.code, label: x.code };
        });
        console.log(options);
        this.setState({
          postcodeOptions: options,
          // suburb: options[0].value,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  retrieveProfile = () => {
    this.setState({ isLoading: true });
    this.props
      .protectedFetch(config.backendServer + `customers/${this.idUser}/`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        console.log(res);
        this.setState({
          profile: res,
          fieldsEdited: false,
          first_name: res.first_name,
          last_name: res.last_name,
          company: res.company,
          country: res.country,
          address_line_one: res.address_line_one,
          address_line_two: res.address_line_two,
          stateVal: res.state,
          suburb: res.suburb,
          postcode: res.postcode,
          mobile: res.mobile,
          isLoading: false,
        });
        this.searchLocationState(res.country);
        this.searchLocationPostcode(res.suburb, res.state);
        this.searchLocationSuburb(res.state);
        // let stateToSet = { isLoading: false };
        // for (const field of this.inputFields) {
        //   stateToSet[field.id] = res[field.id] || "";
        // }
        // this.setState(stateToSet);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      [id]: value,
      fieldsEdited: true,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let dataToSend = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      company: this.state.company,
      address_line_one: this.state.address_line_one,
      address_line_two: this.state.address_line_two,
      state: this.state.stateVal,
      suburb: this.state.suburb,
      postcode: this.state.postcode,
      mobile: this.state.mobile,
    };

    this.props
      .protectedFetch(config.backendServer + `customers/${this.idUser}/`, "PATCH", dataToSend, (res) => res.ok)
      .then((res) => {
        console.log(res);
        // On resolved and rejected, need to take off loading screen
        this.setState({
          showSuccess: true,
          isLoading: false,
          fieldsEdited: false,
        });
      })
      .catch(() => {
        this.setState({
          showFailure: true,
          isLoading: false,
          fieldsEdited: false,
        });
      });
  };

  render() {
    const countryValue = countryOptions.find((x) => x.value === this.state.country);
    if (this.state.isLoading) {
      return <LoadingPage />;
    }
    // if (this.state.showSuccess && this.props.location.state && this.props.location.state.goToDevice) {
    //   // If success and coming from click on load (setting up profile view)
    //   return <Redirect to={"/addnewdevice"} />;
    // }
    // if (this.state.showSuccess) {
    //   // If success and coming from click on load (setting up profile view)
    //   return <Redirect to={"/"} />;
    // }

    // const inputFields = this.inputFields;
    return (
      <div className={"page"}>
        <h2 className={"pageHeader"}>My Details</h2>
        <div className={"pageContent"}>
          <form onSubmit={this.handleSubmit}>
            <div className="row mb-3">
              <div className="col">
                <label>First Name *</label>
                <input
                  type={"text"}
                  value={this.state.first_name}
                  required={true}
                  onChange={this.handleInputChange}
                  id="first_name"
                  placeholder="Input first name"
                  className="form-control"
                />
              </div>
              <div className="col">
                <label>Last Name *</label>
                <input
                  className="form-control"
                  type={"text"}
                  value={this.state.last_name}
                  required={true}
                  onChange={this.handleInputChange}
                  id="last_name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>{"Company (if any)"}</label>
                <input
                  className="form-control"
                  type={"text"}
                  value={this.state.company}
                  onChange={this.handleInputChange}
                  id="company"
                  placeholder="Input company name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Address line 1 </label>
                <input
                  type={"text"}
                  value={this.state.address_line_one}
                  required={true}
                  onChange={this.handleInputChange}
                  id="address_line_one"
                  placeholder="Input address line"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Address line 2</label>
                <input
                  type={"text"}
                  value={this.state.address_line_two}
                  onChange={this.handleInputChange}
                  id="address_line_two"
                  placeholder="Input address line"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className={"col"}>
                <label className={"single-warranty-details-label"}>Country</label>
                <Select
                  isDisabled
                  id={"deviceId"}
                  data-testid="assign-to-select"
                  value={countryValue}
                  options={countryOptions}
                  onChange={(obj) => {
                    this.setState({ country: obj.value });
                    this.searchLocationState(obj.value);
                  }}
                />
              </div>
              <div className="col">
                <label>State</label>
                {this.state.country === "AU" ? (
                  <Select
                    required
                    options={this.state.stateOptions}
                    value={
                      this.state.stateOptions.find((x) => x.value === this.state.stateVal) || {
                        value: this.state.stateVal,
                        label: this.state.stateName,
                      }
                    }
                    onChange={(obj) => {
                      this.setState({ stateVal: obj.value, suburb: "", stateName: obj.label, fieldsEdited: true });
                      this.searchLocationSuburb(obj.value);
                    }}
                    // onInputChange={(val) => {
                    //   if (val.length > 2) this.searchLocationState(val);
                    // }}
                  />
                ) : (
                  <input
                    type="text"
                    id="stateVal"
                    required={true}
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.stateVal}
                  />
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Suburb</label>

                {this.state.country === "AU" ? (
                  <Select
                    required
                    options={this.state.suburbOptions}
                    onChange={(obj) => {
                      this.setState({ suburb: obj.value, fieldsEdited: true });
                      this.searchLocationPostcode(obj.value, this.state.stateName);
                    }}
                    value={
                      this.state.suburbOptions.find((x) => x.value === this.state.suburb) || {
                        value: this.state.suburb,
                        label: this.state.suburb,
                      }
                    }
                    onInputChange={(val) => {
                      if (val.length > 2) this.searchLocationSuburb(this.state.stateVal, val);
                    }}
                  />
                ) : (
                  <input
                    type="text"
                    id="suburb"
                    required={true}
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.suburb}
                  />
                )}
              </div>
              <div className="col">
                <label>Postcode</label>
                {this.state.postcodeOptions.length > 0 ? (
                  <Select
                    required
                    options={this.state.postcodeOptions}
                    onChange={(obj) => this.setState({ postcode: obj.value, fieldsEdited: true })}
                    value={
                      this.state.postcodeOptions.find((x) => x.value === this.state.postcode) || {
                        value: this.state.postcode,
                        label: this.state.postcode,
                      }
                    }
                  />
                ) : (
                  <input
                    type="text"
                    id="postcode"
                    required={true}
                    className="form-control"
                    onChange={this.handleInputChange}
                    value={this.state.postcode}
                  />
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Email</label>
                <input className="form-control" type={"text"} value={this.state.profile?.email} disabled />
              </div>
              <div className="col ">
                <label>Phone</label>
                <input
                  type={"text"}
                  value={this.state.mobile}
                  required={true}
                  onChange={this.handleInputChange}
                  id="mobile"
                  placeholder="Input phone"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-field row">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/";
                }}
                className={"k-btn k-btn-outline-primary col m-1 text-decoration-none"}
              >
                Close
              </button>
              <button
                className={`k-btn col m-1 ${this.state.fieldsEdited ? "k-btn-primary" : "k-btn-outline-primary"}`}
                type="submit"
                disabled={!this.state.fieldsEdited}
              >
                {this.props.location.state && this.props.location.state.goToDevice ? "Next" : "Update"}
              </button>
            </div>
          </form>
        </div>
        <Modal
          isOpen={this.state.showSuccess}
          onRequestClose={() => this.setState({ showSuccess: false })}
          contentLabel={"Profile has been updated."}
          className={"info-modal border-0"}
        >
          <div className="card">
            <button onClick={() => this.setState({ showSuccess: false })} className={"info-modal-close"}>
              <MdClose />
            </button>
            <div className="card-header">Profile</div>
            <div className="card-body">
              <div className={"info-main-text"}>Thank you</div>
              <div className={"info-sub-text"}>Your profile has been updated.</div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showFailure}
          onRequestClose={() => this.setState({ showFailure: false })}
          contentLabel={"Something has gone wrong."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ showFailure: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Something has gone wrong</div>
          <div className={"info-sub-text"}>Please refresh the page before continuing.</div>
        </Modal>
      </div>
    );
  }
}
