import React, { Component } from "react";
import utilityFunctions from "../../store/utilityFunctions";
import errorFunctions from "../../store/errorFunctions";
import config from "../../config";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      faqs: [],
      activeFaqId: -1,
      // Only allow button click when something has been edited
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  retrieveFaq = () => {
    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + "support/faq/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({
          faqs: res,
        });
      })
      .catch(() => {
        // console.log(err);
        this.setState({
          loading: false,
        });
      });
  };
  componentDidMount() {
    this.retrieveFaq();
  }
  render() {
    return (
      <div className={"page"}>
        <h3 onClick={() => this.retrieveFaq()}>Frequestly Asked Questions (FAQs)</h3>
        <div>
          {this.state.faqs.map((faq, index) => {
            const isShowAnswer = this.state.activeFaqId === faq.id;
            return (
              <div className="m-3" style={{ cursor: "pointer" }}>
                <div
                  className="fw-bold"
                  onClick={() => {
                    if (isShowAnswer) this.setState({ activeFaqId: -1 });
                    else this.setState({ activeFaqId: faq.id });
                  }}
                >
                  {isShowAnswer ? <FaMinusCircle className="text-primary" /> : <FaPlusCircle className="text-secondary" />}
                  <span className="m-2">{index + 1}.</span>
                  <span>{faq.question}</span>
                </div>
                {isShowAnswer && <div className="m-3 border p-2 rounded" dangerouslySetInnerHTML={{ __html: faq.answer }} />}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
